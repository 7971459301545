
import Reveal from '../reveal/Reveal'
import TextAnimator from 'modules/text-animator/TextAnimator'

export default class TextReveal extends Reveal {
  constructor (el, textOptions, progress) {
    super(el, progress)
    this.title = new TextAnimator(el, textOptions)
  }

  onAppear () {
    this.title.animateIn()
  }

  onDisappear () {
    this.title.animateOut(0, true)
  }

  flush () {
    super.flush()
    this.title.flush()
    this.title = null
  }
}

const _if = condition => new Promise((resolve, reject) => (condition ? resolve : reject)())
const wait = time => new Promise(resolve => setTimeout(resolve, time))

const defer = () => {
  const promise = new Promise((resolve, reject) => {
    promise.resolve = resolve
    promise.reject = reject
  })
  return promise
}

export default {
  if: _if,
  wait,
  defer
}

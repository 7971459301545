import detect from './detect'
import style from 'core/style'
import debounce from 'lodash/debounce'

let cache = {}

let root = false
let ignoreScrollResize = false

const dimension = () => {
  if (cache.dimension) return cache.dimension

  var dimension = {
    width: !root ? window.innerWidth : root.offsetWidth,
    height: window.innerHeight
    // height: !root ? window.innerHeight : root.offsetHeight
  }

  // Ignore ios resize on scroll (wheen bottom bar disappear)
  if (ignoreScrollResize &&
    detect.iphone &&
    cache.dimension &&
    cache.dimension.width === dimension.width)
    dimension.height = cache.dimension.height

  dimension.ratio = dimension.width / dimension.height

  return (cache.dimension = dimension)
}

const width = () => dimension().width
const height = () => dimension().height

const testMQ = (mq) => () => cache[mq] || (cache[mq] = window.matchMedia(mq).matches)

const clear = () => {
  cache = {}
}

let listeners = []

const resize = debounce(() => {
  clear()
  listeners.forEach((listener) => listener.resize && listener.resize())
}, 100)

window.addEventListener('resize', resize)

const add = (listener) => listeners.push(listener)
const remove = (listener) => (listeners = listeners.filter(item => item !== listener))

const react = (Target) => {
  const componentDidMount = Target.prototype.componentDidMount
  Target.prototype.componentDidMount = function () {
    add(this)
    componentDidMount && componentDidMount.call(this)
  }

  const componentWillUnmount = Target.prototype.componentWillUnmount
  Target.prototype.componentWillUnmount = function () {
    remove(this)
    componentWillUnmount && componentWillUnmount.call(this)
  }
}

const setRoot = r => (root = r)
const setIgnoreScrollResize = i => (ignoreScrollResize = !!i)

export default {
  dimension,
  width,
  height,
  clear,
  react,
  add,
  remove,
  setRoot,
  setIgnoreScrollResize,
  mq: {
    tablet: testMQ(`(max-width: ${style.tabletMaxWidth})`),
    tabletPortrait: testMQ(`(max-width: ${style.tabletPortraitMaxWidth})`),
    phone: testMQ(`(max-width: ${style.phoneMaxWidth})`),
    phonePortrait: testMQ(`(max-width: ${style.phonePortraitMaxWidth})`)
  }
}

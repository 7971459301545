import VirtualScroll from 'virtual-scroll'
import Emitter from 'tiny-emitter'
import detect from 'helpers/detect'
import resize from 'helpers/resize'
import math from 'helpers/math'
import raf from '@internet/raf'
import 'scrolling-element'

import anime from 'animejs'

let instance
let scrollTop = document.scrollingElement.scrollTop
let scrollBeforeLock = false
let resistance = false
let animating = false

const desktop = !detect.mobile && !detect.tablet
const virtual = desktop && !detect.windows
const emitter = new Emitter()
const multiplier = detect.firefox ? 0.4 : detect.windows ? 1 : 0.15

const init = () => {
  instance = new VirtualScroll({
    el: document.scrollingElement,
    mouseMultiplier: multiplier,
    firefoxMultiplier: 30 / multiplier,
    passive: false
  })

  instance.on(scroll)
  window.addEventListener('scroll', pageScroll)
}

const lock = () => {
  scrollBeforeLock = scrollTop
  document.scrollingElement.classList.add('scroll-lock')
}

const unlock = () => {
  document.scrollingElement.classList.remove('scroll-lock')
  if (scrollBeforeLock) document.scrollingElement.scrollTop = scrollBeforeLock
  scrollBeforeLock = false
}

const pageScroll = (event) => {
  const _scrollTop = document.scrollingElement.scrollTop
  if (_scrollTop === scrollTop) return

  testScroll(_scrollTop)
  scrollTop = _scrollTop
  emitter.emit('', event)
}

const resistanceHeight = 75
const resistanceStrengh = 20
const resistanceEasing = anime.penner.easeInExpo(0, 1)

// const resist = () => {
// const strengh = (scrollTop / resistanceHeight)
// if (strengh >= 1) return
// document.scrollingElement.scrollTop = scrollTop - strengh * resistanceStrengh
// }

const getDelta = (event) => {
  const { deltaY } = event

  if (resistance && deltaY < 0 && scrollTop < resistanceHeight) {
    let strengh = scrollTop / resistanceHeight
    strengh = math.clamp(strengh, 0, 1)
    strengh = resistanceEasing(strengh)

    const newDelta = deltaY * math.mapClamp(strengh, 0, 1, 0.5, 1)
    // const newDelta = deltaY * math.mapClamp(strengh, 0, 1, 0.15, 1)

    if (scrollTop - newDelta > resistanceHeight) {
      const el = document.querySelector('.projects__list')
      const bounding = el.getBoundingClientRect()
      scrollTo(bounding.top + scrollTop, 500, 'easeInOutSine')
      return 0
    }

    return newDelta
  }

  return deltaY
}

const scroll = (event) => {
  if (virtual && event) {
    event.originalEvent.preventDefault()
    if (animating) return
    document.scrollingElement.scrollTop -= getDelta(event)
  }

  if (virtual) {
    const _scrollTop = document.scrollingElement.scrollTop
    testScroll(_scrollTop)
    scrollTop = _scrollTop
  }

  if (desktop && event) emitter.emit('', event)
  emitter.emit('virtual', event)
}

let startScrollDown = false
let hasScrollDown = false
let lastScrollTop = Infinity
const scrollClassname = 'scroll-down'
const topOffset = 100
const offset = 50

const testScroll = (newScrollTop) => {
  if (newScrollTop === scrollTop) return
  if (newScrollTop > Math.max(0, scrollTop)) scrollDown(newScrollTop)
  else scrollUp(newScrollTop)
}

const scrollDown = (newScrollTop) => {
  if (!startScrollDown) {
    startScrollDown = true
    lastScrollTop = newScrollTop
  }

  if (hasScrollDown || Math.abs(lastScrollTop - newScrollTop) < offset) return
  document.body.classList.add(scrollClassname)
  lastScrollTop = newScrollTop
  hasScrollDown = true
}

const scrollUp = (newScrollTop) => {
  if (startScrollDown) {
    startScrollDown = false
    lastScrollTop = newScrollTop
  }

  if (!hasScrollDown || (Math.abs(lastScrollTop - newScrollTop) < offset && newScrollTop > topOffset)) return
  document.body.classList.remove(scrollClassname)
  hasScrollDown = false
}

const scrollTo = (_scrollTop, duration = 400, easing = 'easeInOutQuad') => {
  if (animating) return
  animating = true
  anime({
    targets: document.scrollingElement,
    scrollTop: [scrollTop, _scrollTop],
    // scrollTop: _scrollTop,
    duration,
    easing,
    complete: () => (animating = false),
    update: () => pageScroll({})
  })
}

export default {
  init,
  lock,
  unlock,
  scrollTo,
  resist: () => {
    if (!virtual) return
    resistance = true
    // raf.add(resist)
  },
  release: () => {
    if (!virtual) return
    resistance = false
    // raf.remove(resist)
  },
  instance: () => ({
    on: (cb) => emitter.on('', cb),
    off: (cb) => emitter.off('', cb)
  }),
  virtual: () => ({
    on: (cb) => emitter.on('virtual', cb),
    off: (cb) => emitter.off('virtual', cb)
  }),
  scrollTop: () => scrollTop,
  reset: () => scroll() && unlock()
}

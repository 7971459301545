import SuperPage from 'navigation/SuperPage'

class Projects extends SuperPage {
  constructor (el) {
    super(...arguments)
    this.blocks = Array.from(this.el.querySelectorAll('.projects__item-inner'))
    this.parent = this.el.querySelector('.projects__list')
  }

  resize () {
    super.resize()
    const { length } = this.blocks
    const item = Math.floor(length / 2)
    let block = this.blocks[3]

    if (!block) block = this.blocks[item]

    if (!block) return

    const topBackground = (block.offsetTop + block.offsetHeight / 2)
    this.el.style.setProperty('--top-background', `${(topBackground) >> 0}px`)
  }

  flush () {
    super.flush()
    this.blocks = null
    this.parent = null
  }
}

Projects.pageName = 'Projects'

export default Projects


import resize from 'helpers/resize'
import scroll from 'core/scroll'
import delay from 'lodash/delay'

import detect from 'helpers/detect'

export default class Scroller {
  constructor (el, callback) {
    if (!detect.desktop) return

    this.el = el
    this.callback = callback

    this.resize()
    resize.add(this)
    scroll.reset()
    scroll.instance().on(this.scroll)
    delay(this.resize, 100)
    delay(this.resize, 500)
  }

  scroll = () => {
    const scrollTop = scroll.scrollTop()

    const notInViewport = this.bounds.top > (scrollTop + resize.height()) || this.bounds.bottom < scrollTop
    if (notInViewport) return

    let offset = (((this.bounds.center - scrollTop)) / resize.height())
    offset = Math.min(1, Math.max(-1, offset - 0.5))

    if (offset === this.offset) return

    this.callback && this.callback(this.el, offset)
    this.offset = offset
  }

  flush () {
    if (!detect.desktop) return

    this.el = null
    this.bounds = null
    resize.remove(this)
    scroll.instance().off(this.scroll)
  }

  resize = () => {
    const bound = this.el && this.el.parentNode && this.el.parentNode.getBoundingClientRect()
    if (!bound) return
    this.bounds = {}
    this.bounds.top = bound.top + scroll.scrollTop()
    this.bounds.bottom = bound.bottom + scroll.scrollTop()
    this.bounds.center = (bound.top + bound.height / 2) + scroll.scrollTop()
    this.scroll()
  }
}

import 'normalize.css/normalize.css'
import 'styles/main.scss'

import Main from 'sections/Main'
import 'helpers/detect'

window.onload = () => new Main(document.body)

const nameStyle = 'background: #000; color: #bbb; font-weight: bold; font-size: 18px; font-family: "Helvetica Neue'
const baseStyle = 'background: #000; color: #fff; font-weight: bold; font-size: 18px; font-family: "Helvetica Neue"'

console.log(
  '%c Designed by %cThomas Daeffler%c & developed by %cJonas Folletête ',
  baseStyle, nameStyle, baseStyle, nameStyle
)

if (module.hot) module.hot.accept()

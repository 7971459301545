const splice = (string, start, delCount, newSubStr) => string.slice(0, start) + newSubStr + string.slice(start + Math.abs(delCount))

const limitText = (text, limit, ellipsis = true) => {
  if (ellipsis) limit -= 3

  if (text.length > limit) {
    var trim = text.substr(0, limit)
    trim = trim.substr(0, Math.min(trim.length, trim.lastIndexOf(' ')))
    if (ellipsis) trim += '...'

    return trim
  } else {
    return text
  }
}

const spanify = (text, spanLetter = true, spanInnerLetter = false) => {
  const spnf = (t, cN) => `<span class="${cN}">${t}</span>`
  const letter = l => (spanInnerLetter ? spnf(spnf(l, 'letter'), 'inner-letter') : spnf(l, 'letter'))
  const letters = w => (spanLetter ? w.split('').map(letter).join('') : spnf(w, 'inner-word'))
  const words = w => (spnf(letters(w), 'word'))
  return text.split(' ').map(words).join(' ')
}

const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

let _div
const div = () => (_div || (_div = document.createElement('div')))

const escape = (text) => {
  div().textContent = text
  div().innerText = text
  return div().innerHTML
}

export default {
  splice,
  limitText,
  spanify,
  capitalize,
  escape
}

import SuperPage from 'navigation/SuperPage'
import router from '../../core/router'

class Home extends SuperPage {
  constructor (el) {
    super(...arguments)
    this.current = 0
    this.svgs = this.el.querySelectorAll('.home__drawing')
    this.link = this.el.querySelector('.home__mobile-link')
  }

  prepare () {
    super.prepare()
    this.interval = setInterval(this.tick, 750)
  }

  show (callback, previousPage) {
    super.show(() => {
      if (this.link.offsetWidth) {
        this.timeout = setTimeout(() => {
          router.navigate(this.link.getAttribute('href'))
        }, 2000)
      }
      callback()
    }, previousPage)
  }

  tick = () => {
    this.svgs[this.current].style.display = 'none'
    this.current++
    if (this.current >= this.svgs.length) this.current = 0
    this.svgs[this.current].style.display = 'block'
  }

  flush () {
    super.flush()
    clearInterval(this.interval)
    clearTimeout(this.timeout)
    this.svgs = null
  }
}

Home.pageName = 'Home'

export default Home

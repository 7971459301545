class Page {
  state = { shown: false, hidden: false }

  constructor (el) {
    this.el = el
  }

  pageName () { return this.constructor.pageName || this.constructor.name || true }

  prepare (previousPage) {
    this.el.style.opacity = 0
  }

  askShow = previousPage => new Promise((resolve, reject) => {
    this.show(() => {
      this.state.shown = true
      resolve()
    }, previousPage)
  })

  show (callback, previousPage) {
    this.el.style.opacity = 1
    callback()
  }

  askHide = nextPage => new Promise((resolve, reject) => {
    this.hide(() => {
      this.state.hidden = true
      resolve()
    }, nextPage)
  })

  hide (callback, nextPage) {
    this.el.style.opacity = 0
    callback()
  }

  flush () {}

  resize () {}
}

export default Page

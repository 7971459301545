
import resize from 'helpers/resize'
import scroll from 'core/scroll'

export default class Reveal {
  constructor (el, progress = 0.1, className = 'revealed') {
    this.el = el
    this.progress = progress
    this.className = className

    setTimeout(() => this.resize(), 1)
    scroll.instance().on(this.scroll)
  }

  visible = false

  scroll = () => {
    const { scrollTop } = scroll
    const visible = (this.bounds.top - scrollTop()) < ((1 - this.progress) * resize.height())

    if (visible !== this.visible) {
      this.visible = visible
      if (visible) this.onAppear()
      else this.onDisappear()
    }
  }

  onAppear () {
    this.el.classList.add(this.className)
  }

  onDisappear () {
    this.el.classList.remove(this.className)
  }

  resize = () => {
    const { scrollTop } = scroll
    const { top, bottom } = this.el.getBoundingClientRect()
    this.bounds = {
      top: top + scrollTop(),
      bottom: bottom + scrollTop()
    }

    this.scroll()
  }

  flush () {
    scroll.instance().off(this.scroll)
  }
}

import PageManager from 'navigation/SuperPageManager'

import Page from 'navigation/SuperPage'

import scroll from 'core/scroll'

import resize from 'helpers/resize'
import Project from './project/Project'
import Projects from './projects/Projects'
import Home from './home/Home'

class Main {
  constructor (el) {
    this.el = el
    this.createVirtualScroll()
    this.createPageManager()

    resize.root = document.querySelector('.container')
    this.listenResize()
  }

  createPageManager () {
    const panels = Array.from(this.el.querySelectorAll('.panel'))

    this.pageManager = new PageManager(
      document.querySelector('.container'),
      '.page',
      [
        { route: '/*-projets/*', pageClass: Project },
        { route: '/*-projets/', pageClass: Projects },
        { route: '/home', pageClass: Home },
        { route: '/*', pageClass: Page },
        { route: '/', pageClass: Home }
      ],
      { panels }
    )
  }

  createVirtualScroll () {
    scroll.init()
  }

  listenResize () {
    resize.add(this)
    this.resize()
  }

  resize = () => {
    this.pageManager.resize()
  };
}

export default Main

import { omitBy, remove } from 'lodash-es'
import SuperPage from 'navigation/SuperPage'

class Project extends SuperPage {
  constructor (el) {
    super(...arguments)
    this.blocks = Array.from(this.el.querySelectorAll('.project__block'))
    this.parent = this.el.querySelector('.project__wrapper')
    this.button = this.el.querySelector('.project__headline-description-button')
    this.inner = this.el.querySelector('.project__headline-description-inner')
    this.defineBlocks()
  }

  prepare () {
    super.prepare(...arguments)
    this.button && this.button.addEventListener('click', this.toggleDescription)
  }

  toggleDescription= () => {
    this.button.parentNode.classList.toggle('opened')
  }

  defineBlocks () {
    let blocks = Object.values(omitBy(this.blocks, (b) => {
      return b.classList.contains('project__description') || b.classList.contains('project__diptych_text')
    }))

    if (blocks.length <= 1) blocks = this.blocks

    const { length } = blocks

    const top = 0
    const bottom = Math.max(0, length - 2) || Math.max(0, length - 1)

    this.topBlock = blocks[top].querySelector('img') || blocks[top]
    this.bottomBlock = blocks[bottom].querySelector('img') || blocks[bottom]
  }

  resize () {
    super.resize()

    const topBackground = this.parent.offsetTop + this.topBlock.offsetTop + this.topBlock.offsetHeight / 2
    const bottomBackground = this.parent.offsetHeight - (this.bottomBlock.offsetTop + this.bottomBlock.offsetHeight / 2)

    this.el.style.setProperty('--top-background', `${(topBackground) >> 0}px`)
    this.el.style.setProperty('--bottom-background', `${(bottomBackground) >> 0}px`)
    this.inner && this.el.style.setProperty('--description-inner', `${(this.inner.offsetHeight) >> 0}px`)
  }

  flush () {
    super.flush()
    this.button && this.button.removeEventListener('click', this.toggleDescription)
    this.button = null
    this.blocks = null
    this.parent = null
  }
}

Project.pageName = 'Project'

export default Project

import reverse from 'lodash/reverse'
import anime from 'animejs'

export default class Menu {
  constructor (el) {
    this.el = el
    this.background = el.querySelector('.menu__background')
    this.links = Array.from(el.querySelectorAll('.menu__logo, .menu__link'))
    this.opened = false
  }

  show (pageName) {
    this.opened = true

    const timeline = anime.timeline({
      duration: 400,
      delay: (el, i, l) => i * 100,
      easing: 'easeOutQuad'
    })

    timeline.add(
      {
        translateX: ['-100%', 0],
        targets: this.background
      },
      0
    )

    timeline.add(
      {
        targets: this.links.slice(0),
        translateX: [-100, 0],
        opacity: [0, 1]
      },
      250
    )

    this.el.style.display = 'flex'
    return timeline.finished
  }

  hide () {
    this.opened = false

    const timeline = anime.timeline({
      duration: 300,
      easing: 'easeInQuad',
      // delay: (el, i, l) => (l - i) * 50
      delay: (el, i, l) => i * 40
    })

    timeline.add(
      {
        translateX: [0, -200],
        opacity: [1, 0],
        targets: this.links
      },
      0
    )

    timeline.add(
      {
        translateX: [0, '-100%'],
        targets: this.background
      },
      200
    )

    return timeline.finished.then(() => {
      this.el.style.display = 'none'
    })
  }

  flush () {}

  resize () {}
}

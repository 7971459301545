import Page from './Page'
import anime from 'animejs'

import scroll from 'core/scroll'
import promise from 'helpers/promise'
import resize from 'helpers/resize'
import Reveal from 'modules/reveal/Reveal'
import ImageScroller from 'modules/image-scroller/ImageScroller'

import map from 'lodash/map'
import each from 'lodash/each'
import Header from 'modules/header/Header'
import TextReveal from '../modules/text-reveal/TextReveal'
import router from '../core/router'
import { reverse, sample } from 'lodash-es'

export default class SuperPage extends Page {
  imagesToLoad = []
  colors = []

  constructor (el, options = {}) {
    super(...arguments)
    this.inner = this.el.querySelector('.page__inner')
    const headerDiv = this.el.querySelector('.header')
    if (headerDiv) this.header = new Header(headerDiv)
    this.panels = options.panels
    this.updatePanelSize()
  }

  showPanel (direct = false) {
    this.randomImagePanel()
    this.resize()
    each(this.panels, panel => (panel.style.display = 'block'))
    // const targets = { translateY: 0 }
    this.displayPanel()

    return anime({
      targets: this.panels,
      scale: [0, 1],
      translateY: [0, 0],
      rotate: ['-180deg', 0],
      opacity: [1, 1],
      // translateY: ['100%', 0],
      easing: 'easeInOutQuart',
      duration: direct ? 1 : 800,
      // update: () => this.updatePanel(targets.translateY)
      delay: (el, i) => i * 100 + 200
    }).finished
  }

  hidePanel (direct = false) {
    return anime({
      targets: reverse(this.panels.slice(0)),
      // opacity: [1, 0],
      scale: [1, 0],
      rotate: [0, '180deg'],
      easing: 'easeInOutQuart',
      duration: direct ? 1 : 800,
      delay: (el, i) => i * 100 + 10

      // update: () => this.updatePanel(targets.translateY)
    }).finished.then(() => {
      this.resetPanel()
    })
  }

  randomImagePanel () {
    this.panels[1].style.backgroundImage = `url(${sample(window.Config.drawings)})`
  }

  updatePanel = value => {
    each(this.panels, (panel, i) => {
      panel.style.transform = `translate(-50%, -50%) rotate(${(i === 0 ? 1 : -1) * 45}deg) translateY(${value})`
    })
  }

  displayPanel () {
    each(this.panels, panel => (panel.style.display = 'inherit'))
    // this.panels.style.transform = 'translateY(100vh)'
  }

  resetPanel () {
    each(this.panels, panel => {
      panel.style.display = 'none'
    })
  }

  prepare (previousPage) {
    this.backToTop = this.el.querySelector('.back_to_top')
    this.backToTop && this.backToTop.addEventListener('click', this.goBackToTop)

    this.previousPage = this.el.querySelector('.previous_page')
    this.previousPage && this.previousPage.addEventListener('click', this.goToPreviousPage)
  }

  goToPreviousPage = (event) => {
    event.preventDefault()
    if (router.lastPage()) router.back()
    else router.navigate(event.currentTarget.getAttribute('href'))
  }

  goBackToTop = (event) => {
    event.preventDefault()
    scroll.scrollTo(0, 800)
  }

  show (callback, previousPage) {
    this.el.classList.add('show-page')
    this.resize()
    return this.hidePanel().then(() => {
      callback && callback()
    })
  }

  askShow = previousPage => new Promise((resolve, reject) => {
    if (!previousPage) this.randomImagePanel()
    this.preloadImages()
      .then(() => { this.onImageLoad() })
    // .then(() => promise.wait(1000))
      .then(() => promise.wait(previousPage && !this.imagesToLoad ? 0 : 200))
      .then(() => {
        this.show(() => {
          this.state.shown = true
          resolve()
        }, previousPage)
      })
  })

  preloadImages () {
    if (!this.imagesToLoad || !this.imagesToLoad.length)
      return Promise.resolve()
    document.body.style.cursor = 'wait'
    return Promise.all(map(this.imagesToLoad, this.loadImage))
  }

  loadImage = img => new Promise(resolve => {
    if (img.naturalWidth !== 0) return resolve()
    img.onload = () => resolve()
  })

  onImageLoad (callback, previousPage) {
    this.reveals = map(
      this.el.querySelectorAll('.reveal-block'),
      el => new Reveal(el)
    )

    this.textReveal = map(
      this.el.querySelectorAll('.reveal-text'),
      el => new TextReveal(el)
    )

    this.scrollers = map(
      this.el.querySelectorAll('.img-scroller'),
      el => new ImageScroller(el)
    )

    document.body.style.cursor = 'inherit'
  }

  hide (callback, nextPage) {
    // this.header && this.header.hide()
    return this.showPanel().then(() => callback && callback())
  }

  hideWithItem (item, callback, nextPage) {
    const bounds = item.getBoundingClientRect()
    const clone = item.cloneNode(true)

    item.style.position = 'fixed'
    item.style.zIndex = 200
    item.style.margin = 0
    item.style.top = bounds.top + 'px'
    item.style.left = bounds.left + 'px'
    item.style.width = bounds.width + 'px'
    item.style.height = bounds.height + 'px'

    clone.style.height = bounds.height + 'px'

    item.parentNode.insertBefore(clone, item)
    this.el.appendChild(item)

    item.classList.add('hover')

    Promise.resolve()
      .then(() => promise.wait(10))
      .then(() => item.classList.add('hide-with-item'))
      .then(() => this.fadeOut())
      .then(() => promise.wait(500))
      .then(() => {
        anime({
          targets: item,
          opacity: {
            value: [1, 0],
            delay: 200,
            duration: 400,
            easing: 'linear'
          },
          translateY: [0, -40],
          duration: 600,
          easing: 'easeInOutSine',
          complete: callback
        })
      })
  }

  flush () {
    this.previousPage && this.previousPage.removeEventListener('click', this.goToPreviousPage)
    this.backToTop && this.backToTop.removeEventListener('click', this.goBackToTop)
    this.textReveal && each(this.textReveal, revealBlock => revealBlock.flush())
    this.reveals && each(this.reveals, revealBlock => revealBlock.flush())
    this.scrollers && each(this.scrollers, scroller => scroller.flush())
    this.header && this.header.flush()
    super.flush()
  }

  updatePanelSize () {
    const size = Math.ceil(Math.sqrt(resize.width() * resize.width() + resize.height() * resize.height()))
    each(this.panels, (panel, i) => {
      panel.style.marginLeft = (-size / 2) + 'px'
      panel.style.marginTop = (-size / 2) + 'px'
      panel.style.width = size + 'px'
      panel.style.height = size + 'px'
    })
  }

  resize () {
    this.updatePanelSize()
    this.textReveal && each(this.textReveal, revealBlock => revealBlock.resize())
    this.reveals && each(this.reveals, revealBlock => revealBlock.resize())
    this.scrollers && each(this.scrollers, scroller => scroller.resize())
    this.header && this.header.resize()
    super.resize()
  }
}
